@charset "utf-8";

@import 'bootstrap';
@import 'carousel';
@import 'pygment';

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.sys-dev-run-collapse {

  button {
    font-family: monospace;

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

  }

  pre.card {
    border-top-left-radius: 0;
  }
}

.logo-article {
  width: 30px;
  // margin-right: 5px;
}

.user-content p a {
  color: black;
  text-decoration: none;
}

.testimonial-title {
  color: gray;
}

.testimonials {
  margin-top: 4rem;
}

.testimonials .logo {
  height: 50px;
  position: relative;
  margin-bottom: 10px;
}
.testimonials .logo img {
  max-height: 100%;
  max-width: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}